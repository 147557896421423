.navigation {
  width: 264px;
  background: white;
  padding: 32px;
  font-family: 'Poppins', sans-serif;

  &__main {
    padding: 15px 0 0 0;
  }

  &__logo {
    padding: 0px 5px 30px 5px;
    height: 66px;
    width: 140px;
    object-fit: contain;
    border-bottom: 1px solid #f2f2f3;
  }

  &__image {
    margin: 0 8px 0 0;
  }

  &__item {
    margin-top: 8px;
    width: 100%;
    border-radius: 8px;
    padding: 12px 12px;
    display: flex;
    align-items: center;
    color: #808086;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    cursor: pointer;
    transition: all 0.2s;

    &.active {
      background: var(--primary-100, #1f44dd);
      color: #fff;
      transition: all 0.2s;

      .navigation__image {
        svg {
          fill: #808086;
        }
      }
    }
  }
}
