.Offers {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__available-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__available {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 24px 24px 18px 24px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
  }

  &__available-for-label {
    color: #1a1a26;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 500;
  }

  &__available-for-description {
    color: #1a1a26;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
  }

  &__available-send-offer-wrapper {
    display: flex;
    gap: 24px;
  }

  &__available-send-offer {
    width: 318px;
  }

  &__available-actions {
    margin-top: 18px;
  }

  &__button {
    border-radius: 4px;
    background: #7c24c2;
    width: 136px;
    min-height: 32px;

    span {
      color: #fff;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__disabled-btn {
    border-radius: 8px;
    background: #e6e6e7;
    width: 136px;
    min-height: 52px;

    span {
      color: #808086;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__list-label {
    color: #1a1a26;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 600;
    width: 30%;
  }

  &__list-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__list-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__list {
    width: 100%;
    float: right;
    border-radius: 12px;
    background: #fff;
    box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
  }

  &__list-no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__list-no-data-icon {
    margin: 0 auto;
  }

  &__list-no-data-description {
    margin-top: 14px;

    p {
      text-align: center;
    }
  }

  &__list-no-data-title {
    color: #1a1a26;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 600;
  }

  &__list-no-data-sub-title {
    margin-top: 8px;
    color: #808086;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  &__list-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 294px;
  }

  &__list-buttons {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__with-draw {
    color: #67666e;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
}
