.ant-segmented {
  font-style: normal;
  font-weight: 500;
  color: #4d5e93;
  padding: 4px;
  border-radius: 40px;
  .ant-segmented-group {
    background-color: #f5f7fa;
    .ant-segmented-item {
      min-width: 135px;
    }
    .ant-segmented-thumb {
      border-radius: 40px;
      background-color: #2a67c3;
    }
  }

  .ant-segmented-item-selected {
    .ant-segmented-item-label {
      background-color: #2a67c3;
      border-radius: 40px;
      color: white;
      text-decoration: none !important;
      transition: color 0.1s;
    }
  }

  .ant-segmented-item {
    border-radius: 40px;
    transition: color 0.1s;
  }
}

.notif {
  margin: 0 24px 0 0;
  width: 52px;

  &__close-btn {
    position: absolute;
    top: 32px;
    right: 16px;
    cursor: pointer;
  }

  &__bill {
    background: #fbefff;
    padding: 12px;
    margin: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;

    &-text {
      color: #01010e;
      font-size: 12px;
      font-weight: 600;
    }

    &-btn {
      height: 32px;
      cursor: pointer;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 95%;
  }

  &__btn-wrapper {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .notif__btn {
      background-image: url('../../assets/Ellipse 534.svg');
      background-size: cover;
      background-position: center;
      transition: background-image 0.5s;
      width: 10px;
      height: 10px;
    }

    &:hover {
      background-color: #e9eef5;
      border-radius: 50%;
      .notif__btn {
        background-image: url('../../assets/Union.svg');
      }
    }
    .notif__btn-read {
      background-image: url('../../assets/Union-read.svg');
      background-size: cover;
      background-position: center;
      transition: background-image 0.5s;
      width: 10px;
      height: 10px;
    }

    &:hover {
      background-color: #e9eef5;
      border-radius: 50%;
      .notif__btn-read {
        background-image: url('../../assets/Ellipse 534.svg');
      }
    }
  }

  &__subtitle {
    font-size: 14px;
    color: #67666e;
    font-weight: 700;
  }

  &__text {
    color: #01010e;
    font-size: 14px;
    font-weight: 500;
  }

  &__time {
    color: #67666e;
    padding: 4px 0 0 0;
    font-size: 12px;
    font-weight: 400;
  }

  &__list {
    padding: 14px 24px;
    max-height: 327px;
    overflow-y: scroll;
  }

  &__main {
    position: relative;
    z-index: 2;
    width: 500px;
    background: white;
    border-radius: 12px;
    box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
  }

  &__rhombus {
    width: 31px;
    height: 31px;
    background-color: white;
    transform: rotate(45deg);
    position: absolute;
    z-index: 1;
    top: -16px;
    right: 169px;
    box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
  }

  &__container {
    position: absolute;
    top: 88px;
    right: 132px;
    height: 0;
    opacity: 0;
    z-index: -1;
    transition: 0.3s ease-in-out, opacity 0.5s;

    .notif__rhombus {
      opacity: 0;

      transition: 0.3s ease-in-out;
    }

    &.open {
      height: 250px;
      opacity: 1;
      z-index: 2;
      transition: 0.3s ease-in-out, opacity 0.1s;

      .notif__rhombus {
        opacity: 1;
        transition: 0.3s ease-in-out;
      }
    }
  }

  &__header {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-100, #e6e6e7);
  }

  &__title {
    color: var(--grey-900, #01010e);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__mark {
    color: var(--primary-100, #1f44dd);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    text-decoration-line: underline;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  &__mark-btn {
    color: var(--primary-100, #1f44dd);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    text-decoration-line: underline;
    padding: 0 24px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  &__item {
    background: white;
    padding: 12px 24px 12px 12px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: #f7f8f8;
      border-radius: 4px;
      transition: all 0.3s;
    }
  }
}
