.header {
  height: 76px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 26px;
  font-family: 'Poppins', sans-serif;

  &__wrapper-notif {
    position: relative;
    height: 37px;
  }

  &__notif-count {
    background-image: url('../../assets/Ellipse\ 534.svg');
    width: 10px;
    height: 10px;
    position: absolute;
    top: 7px;
    right: 7px;
  }

  &__settings {
    display: flex;
    align-items: center;
    margin: 0 -12px 0 0;
  }

  &__image {
    width: 37px;
    cursor: pointer;
    &:hover {
      background-color: #e9eef5;
      border-radius: 50%;
    }
  }

  &__search {
    margin: 0 0 -18px 0;
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__user-info {
    display: flex;
  }

  &__profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 5px;
    min-width: 210px;
    padding: 16px 26px;
    cursor: pointer;

    &.open {
      min-width: 210px;
      border-radius: 8px 8px 0 0;
      height: 57px;
      box-shadow: 0px 3px 15px -3px rgba(13, 21, 55, 0.12);
      padding: 16px 26px;
      transition: all 0.3s;
    }
  }

  &__avatar {
    width: 52px;
  }

  &__name {
    color: #808086;
    font-size: 14px;
    font-weight: 600;
    margin: 0 14px 0 0;
  }

  &__arrow {
    transition: all 0.3s;

    &.open {
      transform: rotate(180deg);
      transition: all 0.3s;
    }
  }

  &__menu {
    position: absolute;
    top: 65px;
    height: 141px;
    width: 210px;
    z-index: 2;
    background: white;
    border-radius: 0 0 8px 8px;
    box-shadow: -1px 5px 8px -3px rgba(13, 21, 55, 0.12);
    border-top: 1px solid #f2f2f3;
    transition: 0.3s ease-in-out, border-top 0s;
    overflow: hidden;

    &.super_admin {
      height: 210px;
      transition: 0.3s ease-in-out, border-top 0s;
    }

    &.close {
      height: 0px;
      border-top: none;
      overflow: hidden;
    }
  }

  &__my-profile {
    display: flex;
    align-items: center;
    padding: 16px 11px;
    color: var(--grey-800, #1a1a26);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    cursor: pointer;
    margin: 10px;
    border-radius: 8px;

    &.active {
      background: var(--grey-50, #1f44dd);
      color: white;

      &:hover {
        background: var(--grey-50, #1f44dd);
      }
    }

    &:hover {
      background: var(--grey-50, #f2f2f3);
    }
  }

  &__item-title {
    margin: 0 0 0 15px;
  }

  &__profile-image {
    margin: 0 8px 0 0;
  }

  &__logout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    color: var(--grey-800, #1a1a26);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    border-radius: 0 0 8px 8px;
    cursor: pointer;
    margin: 10px;
    border-radius: 8px;

    &:hover {
      background: var(--grey-50, #f2f2f3);
    }
  }
}
