.dashboard {
  font-family: 'Poppins', sans-serif;

  &__title {
    color: var(--grey-800, #1a1a26);
    font-size: 32px;
    font-weight: 600;

  }

  &__filters {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
    border-radius: 8px;
    background: #fff;
    padding: 16px;
    margin: 20px 0 0 0;
  }

  &__filters-container {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }
  &__filters-btn {
    margin-left: 20px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 24px 0 0 0;
  }

  .code {
    border-radius: 8px;
    background: var(--gray-0, #fff);
    box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 20px;

    &__btn-link {
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
    }

    &__btn-image {
      margin: 0 10px 0 0;
    }

    &__item {
      width: 25%;

      &-title {
        color: #4d4d56;
        font-size: 12px;
        font-weight: 500;
      }

      &-value {
        color: var(--gray-800, #1a1a26);
        font-size: 14px;
        font-weight: 500;

        &.link {
          height: 21px;
          width: 250px;
          text-wrap: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    background: #fff;
    box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
    padding: 16px;
    width: 23.5%;
    height: 118px;

    &.partner {
      width: 32%;
    }

    &__column {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      height: 100%;

      &:first-child {
        align-items: flex-start;
      }
    }

    &__title {
      color: #67666e;
      font-size: 14px;
      font-weight: 600;
    }

    &__value {
      color: var(--other-green, #017130);
      font-size: 16px;
      font-weight: 500;
    }

    &__number {
      color: var(--gray-800, #1a1a26);
      font-size: 38px;
      font-weight: 500;
      margin: 0px 0 -11px 0;
    }

    &__period {
      color: #808086;
      font-size: 12px;
      font-weight: 500;
      text-align: end;
    }
  }

  .graphs {
    position: relative;
    border-radius: 12px;
    background: #fff;
    box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
    width: 49%;
    height: 270px;
    padding: 16px 24px;

    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__values {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      color: #000;
      font-size: 18px;
      font-weight: 600;
      margin: 0 20px 0 0;
    }
    &__value {
      color: var(--other-green, #017130);
      font-size: 16px;
      font-weight: 500;
    }

    &__number {
      color: var(--gray-800, #1a1a26);
      font-size: 14px;
      font-weight: 500;
      margin: 0 20px 0 0;
    }

    &__period {
      color: var(--neutral-600, #9e9ea4);
      font-size: 12px;
      font-weight: 500;
    }
  }

  .table {
    border-radius: 12px;
    background: #fff;
    box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
    width: 49%;
    height: 270px;
    padding: 16px 24px;
    margin: 0;

    &__title {
      color: var(--gray-800, #1a1a26);
      font-size: 18px;
      font-weight: 600;
    }

    .header {
      border-radius: 4px;
      background: rgba(87, 115, 229, 0.1);
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      margin: 20px 0 0 0;

      &__item {
        color: var(--gray-800, #1a1a26);
        font-size: 14px;
        font-weight: 500;
        width: 25%;
        text-align: end;

        &.title {
          width: 50%;
          text-align: start;
        }
      }
    }

    .row {
      box-shadow: none;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;

      &__item {
        color: var(--gray-800, #1a1a26);
        font-size: 16px;
        font-weight: 500;
        height: 24px;
        width: 25%;
        text-align: end;

        &.title {
          width: 50%;
          color: var(--neutral-600, #838388);
          text-align: start;
          white-space: nowrap;
          width: 280px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__nodata {
      width: 100%;
      height: 164px;
      color: #808086;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .progress {
    border-radius: 12px;
    background: #fff;
    box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
    width: 49%;
    padding: 16px 24px;
    height: 420px;

    &__noData {
      display: flex;
      align-items: center;
      width: 220px;
      color: #808086;
      font-size: 12px;
      font-weight: 400;
    }

    &__title {
      color: var(--gray-800, #1a1a26);
      font-size: 18px;
      font-weight: 600;
      margin: 0 0 24px 0;
    }
  }
  .progress-table {
    margin: 40px 0 0 0;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e6e6e7;

      &-item {
        color: #838388;
        font-size: 12px;
        font-weight: 500;
        padding: 10px;
        width: 25%;

        &.main {
          width: 50%;
        }
      }
    }
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-item {
        color: var(--gray-800, #1a1a26);
        font-size: 14px;
        font-weight: 500;
        padding: 10px;
        width: 25%;
        display: flex;
        align-items: center;

        &.main {
          width: 50%;
        }
      }
    }
  }
}
