@import 'src/variables';

.confirmation-modal {
  font-family: 'Poppins', sans-serif;

  &.informative {
    .ant-modal-content {
      padding: 40px;
    }
  }

  .ant-modal-content {
    width: 600px;
    position: absolute;
    top: 50%; right: 50%;
    transform: translate(50%,-50%);
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  &__image {
    width: 40px;
  }

  &__title {
    color: var(--grey-900, #01010e);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 16px 0 0 0;
    text-align: center;
  }

  &__subtitle {
    color: #808086;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 8px 0 10px 0;
  }

  &__btns {
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
  }
}

.showPreview-modal {
  font-family: 'Poppins', sans-serif;

  .ant-modal-content {
    width: 600px;
    left: -194px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
  &__title {
    color: var(--grey-900, #01010e);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  &__subtitle {
    color: #808086;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin: 8px 0 0 0;
  }

  &__image {
    margin: 32px 0 0 0;
    position: relative;
  }

  &__image-preview {
    position: absolute;
    top: 32px;
    width: 98px;
    left: 11px;
    height: 26px;
    object-fit: cover;
  }

  &__btn {
    margin: 40px 0 0 0;
  }
}
.add-bank-modal {
  font-family: 'Poppins', sans-serif;
  position: relative;

  &__tooltip-triger {
    //position: absolute;
    cursor: pointer;
    color: $blue-100;
    font-weight: 500;
    margin-bottom: 8px;
    display: inline-block;
    //top: 223px;
    //left: 145px;
    //z-index: 3;
  }
  &__tooltip-triger.edit {
    top: 194px;
  }
  &__tooltip-container {
    padding: 10px;
  }
  &__tooltip-title {
    font-size: 12px;
    font-weight: 400;
  }
  &__tooltip-list-item {
    background-color: #284FF4;
    border-radius: 2px;
    position: relative;
    padding: 4px 0 4px 12px;
    margin-top: 2px;
    &:first-child {
      margin-top: 6px;
    }
    &::before {
      content: '\2022';
      color: $gray-0;
      font-size: 18px;
      position: absolute;
      left: 2px;
      top: 45%;
      transform: translateY(-50%);
    }
  }

  &.informative {
    .ant-modal-content {
      padding: 24px;
    }
  }

  .ant-modal-content {
    width: 600px;
    position: absolute;
    top: 50%; right: 50%;
    transform: translate(50%,-50%);
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__image {
    width: 40px;
  }

  &__title {
    color: var(--grey-900, #01010e);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    align-items: start;
    margin-bottom: 24px;
  }

  &__subtitle {
    color: var(--grey-900, #01010e);
    text-align: start;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 8px 0 10px 0;
  }
  &__text {
    color: var(--grey-800, #1A1A26);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    align-items: start;
    margin-bottom: 16px;
  }

  &__btns {
    display: flex;
    justify-content: end;
  }
  &__vetification-container {
    background-color: #E5EBFB;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  &__verificaton-footer {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
  &__verificaton-button {
    background-color: $purple-100;
    color: $gray-0;
    margin-right: 20px;
    &:hover {
      color: $gray-0!important;
      border-color: $gray-0!important;
    }
    &:disabled {
      color: $gray-400!important;
    }
  }
}
.text-error {
  color: $red-700;
}
