.auth {
  display: flex;
  font-style: normal;

  .block {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__image {
      object-fit: cover;
      width: 100%;
      height: 100vh;
    }

    &__login {
      padding: 0 60px;
      height: 419px;
    }

    &__title {
      color: var(--grey-800, #1a1a26);
      font-size: 32px;
      font-weight: 600;
      margin: 0 0 9px 0;
    }

    &__subtitle {
      color: #808086;
      font-size: 14px;
      font-weight: 400;
      padding: 0 0 40px 0;
      border-bottom: 1px solid #e6e6e7;
    }

    &__form {
      padding: 40px 0 0 0;
    }

    &__forgot {
      color: var(--grey-palette-700, #34343e);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: end;
      margin: -17px 0 0 0;
      cursor: pointer;
      text-decoration: underline;
    }

    .info-wrap {
      display: flex;
      align-items: center;
      padding: 24px 0 0 0;

      &__remember {
        margin: 0 0 0 22px;
        height: 32px;
      }

      &__title {
        color: var(--grey-900, #01010e);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}

.forgot-password-modal {
  width: 600px !important;

  &__image {
    margin: 0 0 16px 0;
  }

  &__container {
    font-family: 'Poppins', sans-serif;

    &-success {
      font-family: 'Poppins', sans-serif;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 45px 0 32px 0;
    }
  }

  &__title {
    color: var(--grey-900, #01010e);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__subtitle {
    color: #808086;
    font-size: 14px;
    font-weight: 400;
    margin: 8px 0 32px 0;

    &-success {
      color: #808086;
      font-size: 14px;
      font-weight: 400;
      margin: 8px 0 0px 0;
      width: 319px;
      text-align: center;
    }
  }

  &__btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .ant-modal-close {
    top: 20px;
    right: 24px;
  }
}

.token-page {
  &__rectangle {
    width: 100%;
    height: 100vh;

    &.rectangle-error {
      background: linear-gradient(
        96deg,
        rgba(229, 35, 53, 0.962) 9.62%,
        rgba(229, 35, 53, 0.889) 88.97%
      );
      height: 60vh;
      opacity: 0.3;
    }
  }

  &__image {
    margin: 0 0 24px 0;
  }

  &__subtitle {
    color: var(--gray-500, #67666e);
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 8px 0 50px 0;
  }

  &__logo-container {
    position: absolute;
    top: 0;
    border-radius: 0 0 30px 30px;
    width: 100%;
    height: 95px;
    background: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__code-container {
    position: absolute;
    width: 100%;
    top: 95px;
    height: calc(100vh - 95px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: var(--gray-800, #1a1a26);
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    width: 360px;
    text-align: center;
    line-height: 150%;

    &.expired-title {
      width: 450px;
    }
  }

  &__code {
    margin: 44px 0 44px 0;
  }

  &__code-input {
    width: 60px;
    height: 60px;
    margin: 0 5px;
    color: var(--blue-100, #1f44dd);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    border: none;
    border-radius: 8px;

    &.error {
      border: 1px solid #da4c53;
    }

    &:focus {
      outline: none;
      border: 1px solid var(--blue-100, #1f44dd);
    }
  }
}
