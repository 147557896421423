.ViewApplication {
  padding: 24px;
  width: 100%;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__sub-block {
    flex: 1;
    box-sizing: border-box;
  }

  &__left-side {
    border-right: 1px solid #e6e6e7;
    padding-right: 24px;
  }

  &__right-side {
    padding-left: 24px;
  }

  &__user-info {
    border-bottom: 1px solid #e6e6e7;
    padding-bottom: 24px;
  }

  &__user-name {
    color: #1a1a26;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 600;
  }

  &__user-id-container {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__user-id-label {
    color: #808086;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 500;
  }

  &__user-id {
    color: #1f44dd;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 500;
  }

  &__partner-company-container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__partner-company-label {
    color: #4d4d56;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
  }

  &__partner-company-name {
    color: #1a1a26;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  &__user-additional-info {
    border-bottom: 1px solid #e6e6e7;
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__label {
    color: #4d4d56;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
  }

  &__additional-info {
    margin-top: 8px;
    color: #1a1a26;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  &__user-info-item-wrapper {
    min-width: 246px;
  }

  &__info-item {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__product-info {
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-bottom: 1px solid #e6e6e7;
  }

  &__header-label {
    color: #1a1a26;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
  }

  &__income-info {
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__application-status-information {
    padding-bottom: 24px;
    border-bottom: 1px solid #e6e6e7;
  }

  &__margin {
    margin-top: 24px;
  }

  &__status-information-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__status-information-item-label {
    color: #4d4d56;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  &__status-label {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
  &__green-status-label {
    color: #017130;
  }

  &__red-status-label {
    color: #a80e1c;
  }

  &__purpure-status-label {
    color: #7c24c2;
  }

  &__authorization-info-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__auth-info {
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-bottom: 1px solid #e6e6e7;
    &-concora {
      gap: 0;
    }
  }

  &__application-status-offers {
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__application-available-send-offer {
    width: 100%;
  }

  &__available-send-offer-wrapper {
    display: flex;
    gap: 24px;
  }

  &__no-offers-available {
    width: 100%;
    padding: 24px;
    margin: 0 auto;
    border-radius: 12px;
    box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
  }

  &__list-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}
